import { default as blacklistxdJwyfVAVhMeta } from "/app/src/pages/cashtec/blacklist.vue?macro=true";
import { default as bonusAtMDAbBsc7Meta } from "/app/src/pages/cashtec/buyout/[id]/bonus.vue?macro=true";
import { default as communication9JXyUfxvQIMeta } from "/app/src/pages/cashtec/buyout/[id]/communication.vue?macro=true";
import { default as customerOcQxK9pSyHMeta } from "/app/src/pages/cashtec/buyout/[id]/customer.vue?macro=true";
import { default as detailINi7kCFsRBMeta } from "/app/src/pages/cashtec/buyout/[id]/detail.vue?macro=true";
import { default as log7BY2NfWfRkMeta } from "/app/src/pages/cashtec/buyout/[id]/log.vue?macro=true";
import { default as summarymjdYaUwLnTMeta } from "/app/src/pages/cashtec/buyout/[id]/summary.vue?macro=true";
import { default as _91id_93GX4p05awrFMeta } from "/app/src/pages/cashtec/buyout/[id].vue?macro=true";
import { default as chatswGIVIrIWgGMeta } from "/app/src/pages/cashtec/chats.vue?macro=true";
import { default as export0R6JbQsDF8Meta } from "/app/src/pages/cashtec/export.vue?macro=true";
import { default as indexS8cM4oJqtjMeta } from "/app/src/pages/cashtec/index.vue?macro=true";
import { default as logsUURkEyYi0mMeta } from "/app/src/pages/cashtec/logs.vue?macro=true";
import { default as paymentsRw4v5RwWXlMeta } from "/app/src/pages/cashtec/payments.vue?macro=true";
import { default as bonusRQ2A3UrwRHMeta } from "/app/src/pages/cashtec/setup/bonus.vue?macro=true";
import { default as setupaWISAkzWeeMeta } from "/app/src/pages/cashtec/setup.vue?macro=true";
import { default as tools7QtQsJQBUiMeta } from "/app/src/pages/cashtec/tools.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as login3hUYZUGxmUMeta } from "/app/src/pages/login.vue?macro=true";
import { default as profileTx35vCKuH1Meta } from "/app/src/pages/profile.vue?macro=true";
export default [
  {
    name: "cashtec-blacklist___cs",
    path: "/cashtec/blacklist",
    meta: blacklistxdJwyfVAVhMeta || {},
    component: () => import("/app/src/pages/cashtec/blacklist.vue")
  },
  {
    name: "cashtec-buyout-id___cs",
    path: "/cashtec/buyout/:id()",
    component: () => import("/app/src/pages/cashtec/buyout/[id].vue"),
    children: [
  {
    name: "cashtec-buyout-id-bonus___cs",
    path: "/cashtec/vykup/:id()/bonus",
    meta: bonusAtMDAbBsc7Meta || {},
    component: () => import("/app/src/pages/cashtec/buyout/[id]/bonus.vue")
  },
  {
    name: "cashtec-buyout-id-communication___cs",
    path: "/cashtec/vykup/:id()/komunikace",
    meta: communication9JXyUfxvQIMeta || {},
    component: () => import("/app/src/pages/cashtec/buyout/[id]/communication.vue")
  },
  {
    name: "cashtec-buyout-id-customer___cs",
    path: "/cashtec/vykup/:id()/zakaznik",
    meta: customerOcQxK9pSyHMeta || {},
    component: () => import("/app/src/pages/cashtec/buyout/[id]/customer.vue")
  },
  {
    name: "cashtec-buyout-id-detail___cs",
    path: "/cashtec/vykup/:id()/detail",
    meta: detailINi7kCFsRBMeta || {},
    component: () => import("/app/src/pages/cashtec/buyout/[id]/detail.vue")
  },
  {
    name: "cashtec-buyout-id-log___cs",
    path: "/cashtec/vykup/:id()/log",
    meta: log7BY2NfWfRkMeta || {},
    component: () => import("/app/src/pages/cashtec/buyout/[id]/log.vue")
  },
  {
    name: "cashtec-buyout-id-summary___cs",
    path: "/cashtec/vykup/:id()/sumarizace",
    meta: summarymjdYaUwLnTMeta || {},
    component: () => import("/app/src/pages/cashtec/buyout/[id]/summary.vue")
  }
]
  },
  {
    name: "cashtec-chats___cs",
    path: "/ashtec/chaty",
    meta: chatswGIVIrIWgGMeta || {},
    component: () => import("/app/src/pages/cashtec/chats.vue")
  },
  {
    name: "cashtec-export___cs",
    path: "/cashtec/export",
    meta: export0R6JbQsDF8Meta || {},
    component: () => import("/app/src/pages/cashtec/export.vue")
  },
  {
    name: "cashtec___cs",
    path: "/cashtec",
    meta: indexS8cM4oJqtjMeta || {},
    component: () => import("/app/src/pages/cashtec/index.vue")
  },
  {
    name: "cashtec-logs___cs",
    path: "/cashtec/logy",
    meta: logsUURkEyYi0mMeta || {},
    component: () => import("/app/src/pages/cashtec/logs.vue")
  },
  {
    name: "cashtec-payments___cs",
    path: "/cashtec/platby",
    meta: paymentsRw4v5RwWXlMeta || {},
    component: () => import("/app/src/pages/cashtec/payments.vue")
  },
  {
    name: "cashtec-setup___cs",
    path: "/cashtec/setup",
    component: () => import("/app/src/pages/cashtec/setup.vue"),
    children: [
  {
    name: "cashtec-setup-bonus___cs",
    path: "/cashtec/nastaveni/bonus",
    meta: bonusRQ2A3UrwRHMeta || {},
    component: () => import("/app/src/pages/cashtec/setup/bonus.vue")
  }
]
  },
  {
    name: "cashtec-tools___cs",
    path: "/cashtec/nastroje",
    meta: tools7QtQsJQBUiMeta || {},
    component: () => import("/app/src/pages/cashtec/tools.vue")
  },
  {
    name: "index___cs",
    path: "/",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue")
  },
  {
    name: "login___cs",
    path: "/prihlaseni",
    meta: login3hUYZUGxmUMeta || {},
    component: () => import("/app/src/pages/login.vue")
  },
  {
    name: "profile___cs",
    path: "/profil",
    meta: profileTx35vCKuH1Meta || {},
    component: () => import("/app/src/pages/profile.vue")
  }
]